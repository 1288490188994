 

const clientId = process.env.REACT_APP_THIRDWEB_CLIENT_ID;
//const client = createThirdwebClient({ clientId: clientId, });


export default function ClaimWuliesERC721() {
  

      return (  
             
         <div>
            
        </div>

              
              
      )
}
 

 

import { createContext } from "react";

const ChainContext = createContext({  selectedChain: "base",setSelectedChain: function(chain) {}   });
  
  


export default ChainContext;

 
 
